.userprofile__wrap {
    .profile--head {
        color: var(--Grays-Gray700, #323232);
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 30px;
        letter-spacing: -0.48px;
    }

    .profile--subhead {
        color: var(--CoolGray-90, #21272A);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        margin-top: 8px;
    }

    .knprofile {
        color: #197A56;
        cursor: pointer;
    }

    .profile__body {
        display: flex;
        gap: 16px;
        margin-top: 24px;

        .profile--pic {
            width: 102px;
            height: 102px;
            border-radius: 50%;

            .user--initialIcon {
                height: 100%;
                width: 100%;
                font-size: 36px;
            }
        }

        .profile__data {
            display: flex;
            flex-direction: column;
            gap: 8px;

            .user--name {
                color: #000;
                font-size: 24px;
                font-style: normal;
                font-weight: 300;
                line-height: 30px;
                letter-spacing: -0.48px;

            }

            .user--des {
                color: #000;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 30px;
            }

            .user__emailwrap {
                display: flex;
                align-items: center;
                gap: 16px;

                .user--email {
                    color: #333;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    border-right: 1px solid #B1B1B1;
                    padding-right: 16px;
                }

                .user--phone {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    color: #2F2E41;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                }
            }

            .cases__wrap {
                border-radius: 8px;
                background: rgba(255, 255, 255, 0.81);
                padding: 16px;
                width: 187px;
                height: 80px;
                display: flex;
                align-items: center;
                gap: 8px;

                .cases--img {
                    background: #0977D7;
                    border-radius: 50%;
                    height: 32px;
                    width: 32px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .cases__data {
                    .cases--count {
                        color: var(--CoolGray-90, #21272A);
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 30px;
                        letter-spacing: -0.48px;
                    }

                    .cases--text {
                        color: var(--Text-medium, #696969);
                        font-size: 10px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 16px;
                    }
                }
            }
        }
    }
}

.user__analytics {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin-top: 8px;
}