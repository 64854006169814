.settings__wrapper {
    .settings__pagehead {
        padding: 20px 20px 20px 90px;
        display: flex;
        justify-content: space-between;
        gap: 10px;
        align-items: center;


        .breadcrumb {
            display: flex;
            gap: 0px;
        }
    }

    .settings__body {
        background: linear-gradient(180deg, #FFF 0%, #F1EEEA 100%);
        display: flex;
        gap: 16px;

        .settings__bodyHead {
            background: #FFF;
            padding: 20px 20px 20px 90px;
             .manage--acc {
                color: #000;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 20px;
            }

            .settings__options {
                margin-top: 16px;
                display: flex;
                flex-direction: column;
                gap: 8px;

                .settings--item {
                    padding: 16px;
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    color: #000;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                    cursor: pointer;
                }
                .active{
                    border-left: 2px solid #21BF61;
                }
            }
        }

        .settings__pagedata {
            overflow-y: auto;
            height: calc(100vh - 200px);
            padding: 20px;

            .page--head {
                color: var(--Grays-Gray700, #323232);
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: 30px;
                letter-spacing: -0.48px;
            }
        }
    }
}